define("discourse/plugins/discourse-policy/initializers/extend-for-policy", ["exports", "@ember/object", "discourse/lib/plugin-api", "discourse/lib/utilities", "I18n", "@ember/template-factory"], function (_exports, _object, _pluginApi, _utilities, _I18n, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const SETTINGS = [{
    name: "groups"
  }, {
    name: "version"
  }, {
    name: "renew"
  }, {
    name: "renew-start",
    camelName: "renewStart"
  }, {
    name: "reminder"
  }, {
    name: "accept",
    default: _I18n.default.t("discourse_policy.accept_policy"),
    escape: true
  }, {
    name: "revoke",
    default: _I18n.default.t("discourse_policy.revoke_policy"),
    escape: true
  }];
  function initializePolicy(api) {
    function _buildPolicyAttributes(policy) {
      const form = {};
      SETTINGS.forEach(setting => {
        form[setting.name] = policy.dataset[setting.camelName || setting.name] || setting.default || "";
        if (setting.escape) {
          form[setting.name] = (0, _utilities.escapeExpression)(form[setting.name]);
        }
      });
      if (!form.version || parseInt(form.version, 10) < 1) {
        form.version = 1;
      }
      form.private = policy.dataset.private === "true";
      return _object.default.create(form);
    }
    function attachPolicy(cooked, helper) {
      const policy = cooked.querySelector(".policy");
      if (!policy) {
        return;
      }
      policy.innerHTML = `<div class="policy-body">${policy.innerHTML}</div>`;
      if (!helper) {
        // if no helper it means we are decorating the preview, make it clear it's a policy
        const policyPreview = document.createElement("div");
        policyPreview.classList.add("policy-preview");
        policyPreview.innerText = _I18n.default.t("discourse_policy.title");
        policy.prepend(policyPreview);
        return;
      }
      const post = helper.getModel();
      helper.renderGlimmer(policy, (0, _templateFactory.createTemplateFactory)(
      /*
        <PostPolicy @post={{@data.post}} @policy={{@data.policy}} />
      */
      {
        "id": "aam3h9E8",
        "block": "[[[8,[39,0],null,[[\"@post\",\"@policy\"],[[30,1,[\"post\"]],[30,1,[\"policy\"]]]],null]],[\"@data\"],false,[\"post-policy\"]]",
        "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-policy/initializers/extend-for-policy.js",
        "isStrictMode": false
      }), {
        post,
        policy: _buildPolicyAttributes(policy)
      });
    }
    api.decorateCookedElement(attachPolicy, {
      onlyStream: false,
      id: "discourse-policy"
    });
    api.registerCustomPostMessageCallback("policy_change", (controller, message) => {
      controller.appEvents.trigger("policy:changed", {
        controller,
        message
      });
    });
  }
  var _default = _exports.default = {
    name: "extend-for-policy",
    initialize() {
      (0, _pluginApi.withPluginApi)("0.8.7", initializePolicy);
    }
  };
});