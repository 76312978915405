define("discourse/plugins/discourse-policy/discourse/components/modal/policy-builder", ["exports", "@ember/component", "@glimmer/component", "@glimmer/tracking", "@ember/object", "@ember/utils", "@ember-compat/tracked-built-ins", "discourse/lib/ajax", "discourse/lib/text", "I18n", "@ember/template-factory"], function (_exports, _component, _component2, _tracking, _object, _utils, _trackedBuiltIns, _ajax, _text, _I18n, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <DModal
    @title={{i18n "discourse_policy.builder.title"}}
    @closeModal={{@closeModal}}
    @flash={{this.flash}}
    @flashType="error"
    class="policy-builder"
  >
    <:body>
      <PolicyBuilderForm
        @policy={{this.policy}}
        @onChange={{this.onChangeFormField}}
      />
    </:body>
  
    <:footer>
      {{#if @model.insertMode}}
        <DButton
          @label="discourse_policy.builder.insert"
          @action={{this.insertPolicy}}
          class="btn-primary"
        />
      {{else}}
        <DButton
          @label="discourse_policy.builder.save"
          @action={{this.updatePolicy}}
          @isLoading={{this.isSaving}}
          class="btn-primary"
        />
      {{/if}}
    </:footer>
  </DModal>
  */
  {
    "id": "ivNEAtuk",
    "block": "[[[8,[39,0],[[24,0,\"policy-builder\"]],[[\"@title\",\"@closeModal\",\"@flash\",\"@flashType\"],[[28,[37,1],[\"discourse_policy.builder.title\"],null],[30,1],[30,0,[\"flash\"]],\"error\"]],[[\"body\",\"footer\"],[[[[1,\"\\n    \"],[8,[39,2],null,[[\"@policy\",\"@onChange\"],[[30,0,[\"policy\"]],[30,0,[\"onChangeFormField\"]]]],null],[1,\"\\n  \"]],[]],[[[1,\"\\n\"],[41,[30,2,[\"insertMode\"]],[[[1,\"      \"],[8,[39,4],[[24,0,\"btn-primary\"]],[[\"@label\",\"@action\"],[\"discourse_policy.builder.insert\",[30,0,[\"insertPolicy\"]]]],null],[1,\"\\n\"]],[]],[[[1,\"      \"],[8,[39,4],[[24,0,\"btn-primary\"]],[[\"@label\",\"@action\",\"@isLoading\"],[\"discourse_policy.builder.save\",[30,0,[\"updatePolicy\"]],[30,0,[\"isSaving\"]]]],null],[1,\"\\n\"]],[]]],[1,\"  \"]],[]]]]]],[\"@closeModal\",\"@model\"],false,[\"d-modal\",\"i18n\",\"policy-builder-form\",\"if\",\"d-button\"]]",
    "moduleName": "discourse/plugins/discourse-policy/discourse/components/modal/policy-builder.hbs",
    "isStrictMode": false
  });
  let PolicyBuilder = _exports.default = (_class = class PolicyBuilder extends _component2.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "isSaving", _descriptor, this);
      _initializerDefineProperty(this, "flash", _descriptor2, this);
      _defineProperty(this, "policy", this.args.model.policy || new _trackedBuiltIns.TrackedObject({
        reminder: "daily",
        version: 1
      }));
    }
    onChangeFormField(field, value) {
      this.policy[field] = value;
    }
    insertPolicy() {
      if (!this.validateForm()) {
        return;
      }
      this.args.model.toolbarEvent?.addText(`\n\n[policy ${this.markdownParams}]\n${_I18n.default.t("discourse_policy.accept_policy_template")}\n[/policy]\n\n`);
      this.args.closeModal();
    }
    async updatePolicy() {
      if (!this.validateForm()) {
        return;
      }
      this.isSaving = true;
      try {
        const result = await (0, _ajax.ajax)(`/posts/${this.args.model.post.id}`);
        const raw = result.raw;
        const newRaw = this.replaceRaw(raw);
        if (newRaw) {
          const props = {
            raw: newRaw,
            edit_reason: _I18n.default.t("discourse_policy.edit_reason")
          };
          const cooked = await (0, _text.cook)(raw);
          props.cooked = cooked.string;
          this.args.model.post.save(props);
        }
      } finally {
        this.isSaving = false;
        this.args.closeModal();
      }
    }
    get markdownParams() {
      const markdownParams = [];
      for (const [key, value] of Object.entries(this.policy)) {
        if ((0, _utils.isPresent)(value)) {
          markdownParams.push(`${key}="${value}"`);
        }
      }
      return markdownParams.join(" ");
    }
    replaceRaw(raw) {
      const policyRegex = new RegExp(`\\[policy\\s(.*?)\\]`, "m");
      const policyMatches = raw.match(policyRegex);
      if (policyMatches?.[1]) {
        return raw.replace(policyRegex, `[policy ${this.markdownParams}]`);
      }
      return false;
    }
    validateForm() {
      if ((0, _utils.isBlank)(this.policy.groups)) {
        this.flash = _I18n.default.t("discourse_policy.builder.errors.group");
        return false;
      }
      if ((0, _utils.isBlank)(this.policy.version)) {
        this.flash = _I18n.default.t("discourse_policy.builder.errors.version");
        return false;
      }
      return true;
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "isSaving", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "flash", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "onChangeFormField", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "onChangeFormField"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "insertPolicy", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "insertPolicy"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "updatePolicy", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "updatePolicy"), _class.prototype)), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, PolicyBuilder);
});