define("discourse/plugins/discourse-policy/discourse/components/policy-reminder-input", ["exports", "@ember/component", "@glimmer/component", "I18n", "@ember/template-factory"], function (_exports, _component, _component2, _I18n, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <ComboBox
    @value={{@reminder}}
    @content={{this.validReminders}}
    @onChange={{@onChangeReminder}}
  />
  */
  {
    "id": "UOFh524O",
    "block": "[[[8,[39,0],null,[[\"@value\",\"@content\",\"@onChange\"],[[30,1],[30,0,[\"validReminders\"]],[30,2]]],null]],[\"@reminder\",\"@onChangeReminder\"],false,[\"combo-box\"]]",
    "moduleName": "discourse/plugins/discourse-policy/discourse/components/policy-reminder-input.hbs",
    "isStrictMode": false
  });
  class PolicyReminderInput extends _component2.default {
    constructor() {
      super(...arguments);
      _defineProperty(this, "validReminders", [{
        id: "daily",
        name: _I18n.default.t("daily")
      }, {
        id: "weekly",
        name: _I18n.default.t("weekly")
      }]);
    }
  }
  _exports.default = PolicyReminderInput;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, PolicyReminderInput);
});