define("discourse/plugins/discourse-policy/discourse/connectors/user-preferences-emails-pref-email-settings/preferences-policy-emails", ["exports", "I18n"], function (_exports, _I18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    setupComponent(args, component) {
      const EMAIL_FREQUENCY_OPTIONS = [{
        name: _I18n.default.t(`discourse_policy.preferences.policy_emails.email_frequency_options.never`),
        value: "never"
      }, {
        name: _I18n.default.t(`discourse_policy.preferences.policy_emails.email_frequency_options.when_away`),
        value: "when_away"
      }, {
        name: _I18n.default.t(`discourse_policy.preferences.policy_emails.email_frequency_options.always`),
        value: "always"
      }];
      component.set("policyEmailFrequencyOptions", EMAIL_FREQUENCY_OPTIONS);
    }
  };
});